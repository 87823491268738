<template>
  <div
    v-if="show"
    class="modal modal__right"
    :class="{ show: show === true }"
    :style="{ display: 'block' }"
    id="addModal"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <!--CONFIRM DIALOG-->
    <div
      class="modal modal__confirm show"
      id="confirmModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      v-bind:style="{ display: confirm ? 'block' : 'none' }"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog modal__dialog_alert" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <a
                class="
                  modal__close modal__close--white
                  d-flex
                  align-items-center
                  justify-content-between
                "
                @click="confirmSave()"
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <span class="modal__header__subtitle text--capital">
              For Users
            </span>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                Are you sure you want to proceed?
              </h5>
              <p class="form__label"></p>
              <div class="d-flex mt-5">
                <button
                  data-dismiss="#confirmModal"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--cancel
                    mr-2
                  "
                  @click="confirmSave()"
                >
                  cancel
                </button>
                <button
                  data-dismiss="#confirmModal"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--delete
                    ml-2
                    save-changes
                  "
                  @click="save()"
                >
                  {{ process ? "Saving..." : "Save" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--EDIT USER MODAL-->
    <div class="modal__dialog" role="document">
      <div class="modal__content">
        <div class="modal__head">
          <div class="modal__head__icon">
            <a
              data-dismiss="#addModal"
              class="modal__close modal__close--black"
              @click="closeModal"
            >
              <ion-icon name="arrow-back-outline"></ion-icon>
            </a>
          </div>
          <h5 class="modal__head__title text--capital">edit user</h5>
          <label v-if="loading" class="form__label">
            loading user data please wait...
          </label>
        </div>
        <div class="modal__body">
          <form>
            <div class="row">
              <div class="col-6">
                <div class="form__item">
                  <label class="form__label">first name</label>
                  <input
                    type="text"
                    class="input form__input form__input--lg"
                    placeholder="Enter first name"
                    v-model="userFirstName"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form__item">
                  <label class="form__label">last name</label>
                  <input
                    type="text"
                    class="input form__input form__input--lg"
                    placeholder="Enter last name"
                    v-model="userLastName"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form__item">
                  <label class="form__label">title</label>
                  <input
                    type="text"
                    class="input form__input form__input--lg"
                    placeholder="Enter title"
                    v-model="userTitle"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form__item">
                  <label class="form__label">select role</label>
                  <select
                    class="select select--lg"
                    v-model="roleKey"
                    @change="getPermissionForRole($event)"
                  >
                    <option value="" disabled selected>Select your role</option>
                    <option value="__"></option>
                    <option
                      v-for="row in role"
                      :value="row.roleKey"
                      :key="row.roleKey"
                    >
                      {{ row.roleName }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <ul id="accordion">
              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#userRights"
                  aria-expanded="false"
                  aria-controls="userRights"
                >
                  <h5
                    class="
                      w-100
                      mb-0
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    user rights
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div id="userRights" class="collapse" data-parent="#accordion">
                  <div class="pt-5">
                    <div class="row">
                      <div class="col-6">
                        <div class="d-flex form__item flex-column">
                          <label
                            class="form__label text--black text--capital mb-3"
                            >types</label
                          >
                          <template>
                            <label
                              :for="item"
                              v-for="item in getType"
                              :key="item"
                              v-bind:value="item"
                              class="
                                has-checkbox
                                page__body__header__label
                                text--capital
                                mb-3
                              "
                              >{{ item }}
                              <input
                                type="checkbox"
                                :id="item"
                                v-if="String(item) == 'Administrator'"
                                v-model="isAdministrator"
                                :disabled="disablePermissions"
                                class="form-"
                              />
                              <input
                                type="checkbox"
                                :id="item"
                                v-if="String(item) == 'Teller'"
                                v-model="isTeller"
                                :disabled="disablePermissions"
                                class="form-"
                              />
                              <input
                                type="checkbox"
                                :id="item"
                                v-if="String(item) == 'Account Officer'"
                                v-model="isCreditOfficer"
                                :disabled="disablePermissions"
                                class="form-"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </template>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="d-flex form__item flex-column">
                          <label
                            class="form__label text--black text--capital mb-3"
                            >access rights</label
                          >
                          <template>
                            <label
                              :for="item"
                              v-for="item in accessRightP"
                              :key="item"
                              v-bind:value="item"
                              class="
                                has-checkbox
                                page__body__header__label
                                text--capital
                                mb-3
                              "
                              >{{ item }}
                              <input
                                type="checkbox"
                                :id="item"
                                v-model="accessRightTop[item]"
                                class="form-"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#permissions"
                  aria-expanded="false"
                  aria-controls="permissions"
                >
                  <h5
                    class="
                      w-100
                      mb-0
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    permissions
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div id="permissions" class="collapse" data-parent="#accordion">
                  <div class="pt-5">
                    <label
                      for="customize"
                      class="
                        has-checkbox
                        page__body__header__label
                        text--capital
                        ml-3
                        pb-5
                        text--small text--dark
                      "
                    >Customize user permission
                      <input
                        type="checkbox"
                        id="customize"
                        class="form-"
                        v-model="isCustomize"
                        @change="onSelectedCustomize"
                      />
                      <span class="checkmark"></span>
                    </label>
                    <!-- <input
                      type="text"
                      class="input form__input"
                      placeholder="Search for permissions"
                    /> -->

                    <ul id="accordion-child" class="permissions-child">
                      <div
                        class="p-2"
                        v-for="(row, idx) in permission"
                        :key="row.groupId"
                      >
                        <li
                          data-toggle="collapse"
                          class="sidebar-right__item"
                          :data-target="isCustomize ? `#t${row.groupId}` : ''"
                          aria-expanded="false"
                          :aria-controls="`t${row.groupId}`"
                        >
                          <h5
                            class="
                              w-100
                              sidebar-right__link
                              p-1
                              mb-0
                              d-flex
                              align-items-center
                            "
                          >
                            <ion-icon name="caret-forward-outline"></ion-icon>
                            <label
                              :for="`tc${row.groupId}`"
                              class="
                                has-checkbox
                                page__body__header__label
                                text--capital
                                ml-3
                                text--small text--dark
                              "
                              >{{ row.groupName }}
                              <input
                                type="checkbox"
                                :id="`tc${row.groupId}`"
                                class="form-"
                                v-model="row.isChecked"
                                :disabled="!isCustomize"
                                @change="onSelectedChange(row, row.isChecked)"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </h5>
                        </li>
                        <div
                          :id="`t${row.groupId}`"
                          class="collapse"
                          data-parent="#accordion-child"
                        >
                          <div class="px-5 py-2">
                            <ul
                              :id="`ac${row.groupId}`"
                              v-if="
                                row &&
                                row.subPermissions &&
                                row.subPermissions.length > 0
                              "
                              class="permissions-child"
                            >
                              <div
                                class="p-2"
                                v-for="rowSecond in row.subPermissions"
                                :key="rowSecond.subGroupId"
                              >
                                <li
                                  data-toggle="collapse"
                                  class="sidebar-right__item"
                                  :data-target="isCustomize ? `#st${rowSecond.subGroupId}` : ''"
                                  aria-expanded="false"
                                  :aria-controls="`st${rowSecond.subGroupId}`"
                                >
                                  <h5
                                    class="
                                      w-100
                                      sidebar-right__link
                                      p-1
                                      mb-0
                                      d-flex
                                      align-items-center
                                    "
                                  >
                                    <ion-icon
                                      name="caret-forward-outline"
                                    ></ion-icon>
                                    <label
                                      :for="`st${rowSecond.subGroupId}`"
                                      class="
                                        has-checkbox
                                        page__body__header__label
                                        text--capital
                                        ml-3
                                        text--small text--dark
                                      "
                                      >{{ rowSecond.subGroupName }}
                                      <input
                                        type="checkbox"
                                        :id="`st${rowSecond.subGroupId}`"
                                        class="form-"
                                        v-model="rowSecond.isChecked"
                                        :disabled="!isCustomize"
                                        @change="
                                          onSelectedSubPermissionChange(
                                            row,
                                            rowSecond.subGroupId,
                                            rowSecond.isChecked
                                          )
                                        "
                                      />
                                      <span class="checkmark"></span>
                                    </label>
                                  </h5>
                                </li>
                                <div
                                  :id="`st${rowSecond.subGroupId}`"
                                  class="collapse"
                                  :data-parent="`#ac${row.groupId}`"
                                >
                                  <div class="px-5 py-2">
                                    <ul
                                      :id="`sac${rowSecond.groupId}`"
                                      v-if="
                                        rowSecond &&
                                        rowSecond.permissions &&
                                        rowSecond.permissions.length > 0
                                      "
                                    >
                                      <li
                                        v-for="rowThird in rowSecond.permissions"
                                        :key="rowThird.permissionId"
                                        class="
                                          text--capital
                                          sidebar-right__link
                                        "
                                      >
                                        <label
                                          :for="`tt${rowThird.permissionId}`"
                                          class="
                                            has-checkbox
                                            page__body__header__label
                                            text--capital text--small
                                          "
                                          >{{ rowThird.permissionName }}
                                          <input
                                            type="checkbox"
                                            :id="`tt${rowThird.permissionId}`"
                                            class="form-"
                                            v-model="rowThird.isChecked"
                                            :disabled="!isCustomize"
                                            @change="
                                              onSelectedPermissionsInSubPermissionChange(
                                                row,
                                                rowSecond.subGroupId,
                                                rowThird.permissionId,
                                                rowThird.isChecked
                                              )
                                            "
                                          />
                                          <span class="checkmark"></span>
                                        </label>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <!-- <template v-if="row && row.groupPermissions && row.groupPermissions.length > 0">
                              <li v-for="rowLast in row.groupPermissions" :key="rowLast.permissionId" class="text--capital move-i sidebar-right__link">
                                <label
                                  :for="rowLast.permissionId"
                                  class="has-checkbox page__body__header__label text--capital text--small">
                                  {{ rowLast.permissionName}}
                                  <input type="checkbox" :id="rowLast.permissionId" class="form-" v-model="rowLast.isChecked" :disabled="disablePermissions"/>
                                  <span class="checkmark"></span>
                                </label>
                              </li>
                              </template>  -->
                            </ul>
                            <ul
                              :id="`ac${row.groupId}`"
                              v-if="
                                row &&
                                row.groupPermissions &&
                                row.groupPermissions.length > 0
                              "
                              class="permissions-child"
                            >
                              <div class="">
                                <li
                                  v-for="rowLast in row.groupPermissions"
                                  :key="rowLast.permissionId"
                                  class="
                                    text--capital
                                    move-i
                                    sidebar-right__link
                                  "
                                >
                                  <label
                                    :for="rowLast.permissionId"
                                    class="
                                      has-checkbox
                                      page__body__header__label
                                      text--capital text--small
                                    "
                                  >
                                    {{ rowLast.permissionName }}
                                    <input
                                      type="checkbox"
                                      :id="rowLast.permissionId"
                                      class="form-"
                                      :disabled="!isCustomize"
                                      v-model="rowLast.isChecked"
                                      @change="onGroupPermissionChange(row, rowLast, idx)"
                                    />
                                    <span class="checkmark"></span>
                                  </label>
                                </li>
                              </div>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#userAccess"
                  aria-expanded="false"
                  aria-controls="userAccess"
                >
                  <h5
                    class="
                      w-100
                      mb-0
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    user access
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div id="userAccess" class="collapse" data-parent="#accordion">
                  <div class="pt-5">
                    <div
                      class="d-flex align-items-center justify-content-between"
                    >
                      <div class="form__item">
                        <label
                          for="Federated"
                          class="
                            has-checkbox
                            page__body__header__label
                            text--capital
                          "
                          >Federated Authentication
                          <input
                            type="checkbox"
                            id="Federated"
                            class="form-"
                            disabled
                            v-model="provisionedThroughFederation"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      <div class="form__item">
                        <label
                          for="projectleanlogin"
                          class="
                            has-checkbox
                            page__body__header__label
                            text--capital
                          "
                          >Project Lean Login
                          <input
                            type="checkbox"
                            id="projectleanlogin"
                            class="form-"
                            disabled
                            checked
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div class="form__item">
                      <label class="form__label">username</label>
                      <input
                        type="text"
                        class="input form__input form__input--lg"
                        placeholder="Enter username"
                        v-model="username"
                      />
                    </div>
                    <div class="form__item">
                      <label class="form__label">email address</label>
                      <input
                        type="text"
                        class="input form__input form__input--lg"
                        placeholder="Enter email address"
                        v-model="userEmail"
                      />
                    </div>
                    <!-- <div class="form__item">
                      <label class="form__label">password</label>
                      <input
                        type="text"
                        class="input form__input form__input--lg"
                        placeholder="Enter password"
                        v-model="password"
                      />
                    </div> -->
                  </div>
                </div>
              </div>

              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#contact"
                  aria-expanded="false"
                  aria-controls="contact"
                >
                  <h5
                    class="
                      w-100
                      mb-0
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    contact
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div id="contact" class="collapse" data-parent="#accordion">
                  <div class="pt-5">
                    <div class="row">
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">mobile phone</label>
                          <input
                            type="text"
                            class="input form__input form__input--lg"
                            placeholder="Mobile phone number"
                            v-model="userMobilephone"
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form__item">
                          <label class="form__label">Home phone</label>
                          <input
                            type="text"
                            class="input form__input form__input--lg"
                            placeholder="Home phone number"
                            v-model="userHomephone"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#assignTo"
                  aria-expanded="false"
                  aria-controls="assignTo"
                >
                  <h5
                    class="
                      w-100
                      mb-0
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    assigned to
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div id="assignTo" class="collapse" data-parent="#accordion">
                  <div class="pt-5">
                    <div class="form__item">
                      <label class="form__label">select branch</label>
                      <multiselect
                        v-model="branchKey"
                        tag-placeholder="Add this as new tag"
                        placeholder="Search or add a Branch"
                        label="branchName"
                        track-by="branchName"
                        :options="branches"
                        :preselect-first="true"
                      ></multiselect>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#accessRights"
                  aria-expanded="false"
                  aria-controls="accessRights"
                >
                  <h5
                    class="
                      w-100
                      mb-0
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    access rights
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div
                  id="accessRights"
                  class="collapse"
                  data-parent="#accordion"
                >
                  <div class="pt-5">
                    <div class="form__item form__item--label">
                      <label
                        for="canaccess"
                        class="
                          has-checkbox
                          page__body__header__label
                          text--capital
                          mb-3
                        "
                        >can access all branches
                        <input
                          type="checkbox"
                          id="canaccess"
                          class="form-"
                          v-model="canaccessAllBranch"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <div class="form__item" v-if="!canaccessAllBranch">
                      <multiselect
                        v-model="accessRight.branches"
                        placeholder="Search or add a Branch"
                        label="branchName"
                        :options="branches"
                        track-by="branchName"
                        :multiple="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                      ></multiselect>
                    </div>
                    <div class="form__item" id="branch-area"></div>
                  </div>
                </div>
              </div>

              <!-- <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#transaction"
                  aria-expanded="false"
                  aria-controls="transaction"
                >
                  <h5
                    class="
                      w-100
                      mb-0
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    transaction limits
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div id="transaction" class="collapse" data-parent="#accordion">
                  <div class="pt-5">
                    <div class="row">
                      <div class="col-3">
                        <div class="form__item">
                          <button
                            class="
                              button
                              page__body__header__button
                              button--grey button--sm
                              w-100
                              text--capital
                            "
                            @click="addTransactionLimit()"
                          >
                            <span>Add +</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      class="row align-items-end"
                      v-for="(row, i) in transactionLimits"
                      :key="i"
                    >
                      <div class="col-5">
                        <div class="form__item">
                          <label
                            class="form__label text--black text--capital mb-3"
                            >transaction type</label
                          >
                          <select
                            required
                            class="select select--lg"
                            v-model="row.type"
                          >
                            <option value="" disabled selected>
                              Transaction type
                            </option>

                            <template v-if="!loadingTransactionType">
                              <option
                                v-for="(item, index) in transactionTypeP"
                                :key="index"
                                v-bind:value="item"
                              >
                                {{ item }}
                              </option>
                            </template>
                          </select>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="form__item">
                          <label class="form__label">amount</label>
                          <input
                            type="number"
                            class="input form__input form__input--lg"
                            placeholder="Amount"
                            v-model="row.amount"
                          />
                        </div>
                      </div>
                      <div class="col-3">
                        <div class="form__item">
                          <button
                            class="
                              button
                              page__body__header__button
                              modal__confirm__button--delete
                              button--sm
                              text--black
                              w-100
                              text--capital
                            "
                            @click="deleteTransactionLimit(i)"
                          >
                            <span>delete</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->

              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#notes"
                  aria-expanded="false"
                  aria-controls="notes"
                >
                  <h5
                    class="
                      w-100
                      mb-0
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    notes
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div id="notes" class="collapse" data-parent="#accordion">
                  <div class="pt-5">
                    <div class="form__item form__item--last">
                      <label
                        class="
                          form__label
                          text--regular-alt text--bold text--black
                        "
                        >notes</label
                      >
                      <textarea
                        id=""
                        cols="5"
                        rows="5"
                        class="input form__input"
                        placeholder="Notes"
                        v-model="user_notes"
                      ></textarea>
                      <!-- <textarea id="" cols="30" rows="10"></textarea> -->
                    </div>
                  </div>
                </div>
              </div>
            </ul>
            <div class="form__item">
              <div class="form__action">
                <button
                  data-dismiss="#addModal"
                  type="button"
                  class="button form__button form__button--lg w-30 save-changes"
                  @click="confirmSave()"
                >
                  {{ process ? "Saving..." : "save changes" }}
                </button>
                <a
                  data-dismiss="#addModal"
                  role="button"
                  class="
                    modal__close
                    form__action__text form__action__text--danger
                    text--capital
                    ml6
                  "
                  @click="closeModal"
                >
                  cancel
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
function initialState() {
  return {
    userFirstName: "",
    userLastName: "",
    userTitle: "",
    roleKey: "",
    userKey: "",
    isAdministrator: true,
    isCreditOfficer: false,
    isTeller: false,
    accessRightTop: {},
    accessRight: {
      branches: [],
    },
    userManageBranch: [],
    provisionedThroughFederation: false,
    canaccessAllBranch: false,
    username: "",
    userEmail: "",
    password: "",
    twoFactorAuthentication: false,
    userLanguage: "",
    userMobilephone: "",
    userHomephone: "",
    branchKey: "",
    user_notes: "",
    permission: [],
    transactionLimits: [
      {
        type: "",
        amount: 0,
      },
    ],
    apiConsumerType: "API",
    disablePermissions: false,
    confirm: false,
    process: false,
    isCustomize: false,
  };
}
import { mapState } from "vuex";
import ApiService from "@/core/services/access.service";
import {
  GET_MENU,
  GET_BRANCHES,
  GET_PERMISSION,
} from "@/core/services/store/types";
import Multiselect from "vue-multiselect";
export default {
  name: "CreateUser",
  components: {
    Multiselect,
  },
  props: {
    show: Boolean,
    close: Function,
    getType: Array,
    accessRightP: Array,
    singleUser: Object,
    transactionTypeP: Array,
    role: Array,
    permissionP: Array,
    loginUserKey: String,
    loading: Boolean,
  },
  data: function () {
    return initialState();
  },
  methods: {
    async onSelectedCustomize() {
      if (!this.isCustomize && this.roleKey !== "__") {
        await this.getPermissionForRole({ target: { value: this.roleKey } });
        this.$emit(
          "alertWarning", "Your permission has been reset to the selected role permission."
        );
      }
    },
    onSelectedSubPermissionChange(item, subPermissionId, state) {
      // First we get the permission, sub permission groupId and the state(i.e true or false)
      // Then we map each sub permission of that permission and check if the group id we sent
      // matches any group id in the list of the permission sub permissions.
      for (let sub of item.subPermissions) {
        if (sub.subGroupId === subPermissionId) {
          // Then we set that sub permission to the `state` and the permissions under that sub
          // permission to the `state
          sub.isChecked = state;

          if (sub.permissions.length > 0) {
            for (let subPerm of sub.permissions) {
              subPerm.isChecked = state;
            }
          }
        }
      }
      this.checkSubPermissionsOfPermission(item, state);
    },
    checkSubPermissionsOfPermission(item, state) {
      // If the `state` is true we set the parent permission to the state
      if (state) {
        item.isChecked = state;
      } else {
        // else we map the list sub permissions of that parent permission and check if
        // any of the sub permissions `state` is true for that parent permission
        // if true we set the parent permission `state` as true else we set it to false
        for (let sub of item.subPermissions) {
          if (sub.isChecked) {
            item.isChecked = true;
            return;
          } else {
            item.isChecked = state;
          }
        }
      }
    },
    onSelectedPermissionsInSubPermissionChange(
      permission,
      subPermissionId,
      permissionInSubPermissionId,
      state
    ) {
      // First we get the permission, sub permission groupId and the state(i.e true or false)
      // Then we map each sub permission of that permission and check if the group id we sent
      // matches any group id in the list of the permissions sub permissions.
      for (let sub of permission.subPermissions) {
        if (sub.subGroupId === subPermissionId) {
          if (sub.permissions.length > 0) {
            // Then we map for each permission in the sub permission permissions and check
            // if the permission id we sent matches any in the list of the sub permissions permissions.
            for (let subPerm of sub.permissions) {
              // Then we set the permissions under that sub permission to the `state`
              if (subPerm.permissionId === permissionInSubPermissionId) {
                subPerm.isChecked = state;
              }
            }
          }
        }

        this.checkPermissionsOfSubPermission(permission, subPermissionId, state);
      }
    },
    checkPermissionsOfSubPermission(permission, subPermissionId, state) {
      // If the `state` is true we set the parent permission to the `state`
      // and the sub permission with the matching group id to the `state`
      if (state) {
        for (let sub of permission.subPermissions) {
          if (sub.subGroupId === subPermissionId) {
            sub.isChecked = state;
          }
        }
        permission.isChecked = state;
      } else {
        // else we map the sub permissions of that permission and find the sub
        // permission that the group id matches
        for (let sub of permission.subPermissions) {
          if (sub.subGroupId === subPermissionId) {
            if (sub.permissions.length > 0) {
              // else we map the permissions of that sub permission and check if
              for (let subPerm of sub.permissions) {
                // any of the permissions `state` is true for that sub permission
                // if true we set the parent permission and sub permission `state` as true
                if (subPerm.isChecked) {
                  permission.isChecked = true;
                  sub.isChecked = true;
                  return;
                } else {
                  // else we set the sub permission to the `state` and
                  sub.isChecked = state;

                  // map the list of sub permissions of the parent permission and check if
                  // any of the sub permissions `state` is true for that parent permission
                  // if true we set the parent permission `state` as true else we set it to the `state`
                  for (let sub of permission.subPermissions) {
                    if (sub.isChecked) {
                      permission.isChecked = true;
                      return;
                    } else {
                      permission.isChecked = state;
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    onSelectedChange(item, state) {
      if (item.groupName.toLowerCase() !== "agent dashboard") {
        if (item.groupPermissions && item.groupPermissions.length) {
          for (let child of item.groupPermissions) {
            child.isChecked = state;
          }
        }

        if (item.subPermissions && item.subPermissions.length) {
          for (let sub of item.subPermissions) {
            sub.isChecked = state;

            if (sub.permissions.length > 0) {
              for (let subPerm of sub.permissions) {
                subPerm.isChecked = state;
              }
            }
          }
        }
      } else {
        if (state === false) {
          for (let child of item.groupPermissions) {
            child.isChecked = state;
          }
        }
      }
    },
    onGroupPermissionChange(permission, subPermission, idx) {
      if (permission.groupName.toLowerCase() === "agent dashboard") {
        for (let child of permission.groupPermissions) {
          if (child.permissionId === subPermission.permissionId) {
            child.isChecked = true;
          } else {
            child.isChecked = false;
          }
        }

        for (let child of permission.groupPermissions) {
          if (child.isChecked) {
            permission.isChecked = true;
            return;
          } else {
            permission.isChecked = false;
          }
        }
        this.permission[idx] = permission;
      } else {
        for (let child of permission.groupPermissions) {
          if (child.isChecked) {
            permission.isChecked = true;
            return;
          } else {
            permission.isChecked = false;
          }
        }

        this.permission[idx] = permission;
      }
    },
    addTransactionLimit() {
      this.transactionLimits.push({
        type: "",
        amount: 0,
      });
    },
    getAllPermissions() {
      const arr1 = this.permissionP;
      const arr2 = this.singleUser.permission;
      let newArr = arr1.reduce((acc, eachArr2Elem) => {
        if (arr2.findIndex((eachArr1Elem) => eachArr1Elem.groupId === eachArr2Elem.groupId && eachArr1Elem.groupName === eachArr2Elem.groupName)  === -1) {
          acc.push(eachArr2Elem)
        }
        return acc
      }, [...arr2]);
      this.permission = newArr;
    },
    deleteTransactionLimit(e) {
      if (this.transactionLimits.length == 1) {
        this.$emit("alertError", "At least one transaction must be present");
        return;
      }
      this.transactionLimits.splice(e, 1);
    },
    transformToKey(arr) {
      let res = {};
      arr.map((x) => {
        res[x.type] = x.amount;
      });
      return res;
    },
    transformToVal(ob) {
      this.transactionLimits = [];
      for (let x in ob) {
        this.transactionLimits.push({
          amount: ob[x],
          type: x,
        });
      }
    },
    transformAccessToKey(arr) {
      let res = [];
      arr.map((x) => {
        res.push(x.branchKey);
      });
      return res;
    },
    transformAccessToVal(ob) {
      let res = { branches: [] };
      for (let x in ob) {
        res.branches.push({
          branchName: this.branches.find((i) => i.branchKey == ob[x])
            .branchName,
          branchKey: ob[x],
        });
      }
      return res;
    },
    async getBranches() {
      const data = {
        branchName: null,
        branchState: "Active",
        pageIndex: 1,
        pageSize: 100,
        sortColumn: "",
        isDescending: false,
      };
      this.$store.dispatch(GET_BRANCHES, data);
    },
    getMenu() {
      this.$store.dispatch(GET_MENU);
    },
    resetWindow: function () {
      Object.assign(this.$data, initialState());
    },
    confirmSave() {
      if (!this.confirm) {
        this.confirm = true;
      } else {
        this.confirm = false;
      }
    },
    validate() {
      if (this.userFirstName == "") {
        this.$emit("alertError", "You must provide a first name!");
        return false;
      }
      if (this.userLastName == "") {
        this.$emit("alertError", "You must provide a last name!");
        return false;
      }
      // if (this.userTitle == "") {
      //   this.$emit("alertError", "You must provide a title!");
      //   return false;
      // }
      if (this.username == "") {
        this.$emit("alertError", "You must provide a username!");
        return false;
      }
      if (this.userEmail == "") {
        this.$emit("alertError", "You must provide an email!");
        return false;
      }
      if (this.password == "") {
        this.$emit("alertError", "You must provide a password!");
        return false;
      }
      // if (this.userMobilephone == "") {
      //   this.$emit("alertError", "You must provide a mobile phone!");
      //   return false;
      // }
      // if (this.userHomephone == "") {
      //   this.$emit("alertError", "You must provide a home phone!");
      //   return false;
      // }
      if (this.branchKey == "") {
        this.$emit("alertError", "You must select a branch!");
        return false;
      }
      if (!this.canaccessAllBranch && this.accessRight.branches.length == 0) {
        this.$emit("alertError", "You must select a branch!");
        return false;
      }
      return true;
    },
    closeModal() {
      this.resetWindow();
      this.$emit("close", false);
    },
    save: function () {
      if (!this.validate()) return;
      this.process = true;

      let copyOfPayload = { ...this.$data };
      copyOfPayload.isCustom = this.isCustomize;
      copyOfPayload = {
        ...copyOfPayload,
        branchKey: this.branchKey ? this.branchKey.branchKey : "",
        transactionLimits: this.transformToKey(this.transactionLimits),
        accessRight: copyOfPayload.accessRightTop,
        userManageBranch: this.transformAccessToKey(this.accessRight.branches),
      };
      if (this.canaccessAllBranch) {
        copyOfPayload.userManageBranch = null;
      }

      if (this.roleKey == "__") {
        copyOfPayload.roleKey = "";
      }

      //clear unnecessary keys generated by frontend
      delete copyOfPayload.disablePermissions;
      delete copyOfPayload.confirm;
      delete copyOfPayload.accessRightTop;
      delete copyOfPayload.process;
      delete copyOfPayload.isCustomize;

      ApiService.put(
        `ProleanUser/EditUser`,
        copyOfPayload
      )
        .then((res) => res.data)
        .then((res) => {
          this.confirm = false;
          this.process = false;
          if (res.status) {
            this.$emit("alertSuccess", res.message);
            this.resetWindow();
            if(this.singleUser.userKey === this.loginUserKey) {
              // this.showAutoLogOutModal();
              this.$emit("close", true);
              return;
            }
            this.$emit("close", false);
            this.$emit("reload");
          } else {
            this.$emit("alertError", res.message);
          }
        })
        .catch((e) => {
          this.confirm = false;
          try {
            this.process = false;
            if(e.response.status == 409) {
              this.$emit("alertWarning", e.response.data);
            } else {
              this.$emit("alertError", e.response.data.message);
            }
          } catch (e) {
            this.$emit(
              "alertError",
              "An unexpected Error occurred: Kindly confirm you are connected to the internet"
            );
          }
        });
    },
    getPermission() {
      this.$store.dispatch(GET_PERMISSION);
    },
    getPermissionForRole(e) {
      let key = e.target.value;
      if (key === "__") {
        this.getPermission();
        this.disablePermissions = false;
        return true;
      }

      ApiService.get(
        `RoleManagement/GetRoleByRoleKey/roleKey${key}`
      )
        .then((res) => res.data)
        .then((res) => {
          if (!(res.data && res.data.permission)) return;
          this.disablePermissions = true;
          this.isCustomize = false;
          this.permission = res?.data?.permission || this.permission;
          this.isAdministrator = res.data.isAdministrator;
          this.isCreditOfficer = res.data.isCreditOfficer;
          this.isTeller = res.data.isTeller;
        });
    },
  },
  watch: {
    singleUser(nextProps) {
      const ob = { ...this.$data };
      var self = this;
      // if (Object.keys(nextProps).length == 0) {
      //   self.$emit(
      //     "alertError",
      //     "There is an error from the server with this user"
      //   );
      //   self.resetWindow();
      //   self.$emit("close");
      //   return;
      // }
      Object.keys(nextProps).map(function (key) {
        if (key === "isCustom") {
          self.isCustomize = nextProps[key];
        }
        if (ob.hasOwnProperty(key) && nextProps.hasOwnProperty(key)) {
          if (key === "transactionLimits") {
            self.transformToVal(nextProps[key]);
            return;
          }

          if (key === "accessRight") {
            self.accessRightTop = nextProps[key];
            return;
          }

          if (key === "userManageBranch") {
            self.accessRight = self.transformAccessToVal(nextProps[key]);
            return;
          }

          if (key === "branchKey") {
            self.branchKey = self.branches.filter(
              (x) => x.branchKey == nextProps[key]
            )[0];
            return;
          }

          if (key === "roleKey" && nextProps[key] == "") {
            self.roleKey = "__";
            return;
          }

          if (key === "userPermission") {
            self.permission = nextProps[key];
            return;
          }
          self[key] = nextProps[key];
        }
      });
      if (Object.keys(nextProps).length !== 0) {
        this.getAllPermissions();
      }
    },
  },
  computed: mapState({
    branches: (state) => state.access?.branches?.data?.items || [],
    loadingTransactionType: (state) => state.access?.loadingTransactionType,
  }),

  mounted() {
    this.getMenu();
    this.getBranches();
    //todo
    //track error in edit if it is coming
    //from previous error(so the check shouldnt
    //be only on watch method but when edit is clicked)
  },
};
</script>
