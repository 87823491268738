<template>
  <main class="container">
    <!--ALERT NOTIFICATION-->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
          'toaster--warn': alert.status === 'warn',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>

    <!--CONFIRM STATUS DIALOG-->
    <div
      class="modal modal__confirm show"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      v-bind:style="{ display: confirm_status ? 'block' : 'none' }"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog modal__dialog_alert" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <a
                class="
                  modal__close modal__close--white
                  d-flex
                  align-items-center
                  justify-content-between
                "
                @click="confirmStatus()"
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <span class="modal__header__subtitle text--capital">
              For Access User
            </span>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                Are you sure you want to
                {{ userIsActivated ? "activate" : "deactivate" }} this user?
              </h5>
              <p class="form__label"></p>
              <div class="d-flex mt-5">
                <button
                  data-dismiss="#confirmModal"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--cancel
                    mr-2
                  "
                  @click="confirmStatus()"
                >
                  cancel
                </button>
                <button
                  data-dismiss="#confirmModal"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--delete
                    ml-2
                    save-changes
                  "
                  @click="toggeleItem()"
                >
                  {{
                    process
                      ? "Processing..."
                      : userIsActivated
                      ? "Activate"
                      : "Deactivate"
                  }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--CONFIRM DELETE DIALOG-->
    <div
      class="modal modal__confirm show"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      v-bind:style="{ display: confirm_delete ? 'block' : 'none' }"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog modal__dialog_alert" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <a
                class="
                  modal__close modal__close--white
                  d-flex
                  align-items-center
                  justify-content-between
                "
                @click="confirmDelete()"
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <span class="modal__header__subtitle text--capital">
              For Access User
            </span>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                Are you sure you want to proceed?
              </h5>
              <p class="form__label"></p>
              <div class="d-flex mt-5">
                <button
                  data-dismiss="#confirmModal"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--cancel
                    mr-2
                  "
                  @click="confirmDelete()"
                >
                  cancel
                </button>
                <button
                  data-dismiss="#confirmModal"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--delete
                    ml-2
                    save-changes
                  "
                  @click="deleteItem()"
                >
                  {{ process ? "Processing..." : "Delete" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--CONFIRM RESET PASSWORD ADMIN MODAL-->
    <div
      class="modal modal__confirm show"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      v-bind:style="{ display: confirm_delete ? 'block' : 'none' }"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog modal__dialog_alert" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <a
                class="
                  modal__close modal__close--white
                  d-flex
                  align-items-center
                  justify-content-between
                "
                @click="closeResetModal()"
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <span class="modal__header__subtitle text--capital">
              Admin Reset Password
            </span>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                Are you sure you want to proceed?
              </h5>
              <p class="form__label"></p>
              <div class="d-flex mt-5">
                <button
                  data-dismiss="#confirmModal"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--cancel
                    mr-2
                  "
                  @click="closeResetModal()"
                >
                  cancel
                </button>
                <button
                  data-dismiss="#confirmModal"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--delete
                    ml-2
                    save-changes
                  "
                  @click="confirmResetPassword()"
                >
                  {{ process ? "Processing..." : "Reset" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <CreateUser
      v-bind:show="showCreateModal"
      @close="closeCreateModal"
      @alertSuccess="alertSuccess"
      @reload="getAllUser"
      @alertError="alertError"
      :getType="getType"
      :accessRightP="accessRight"
      :permissionP="permission"
      :transactionTypeP="transactionType"
      :role="allRole"
    />

    <EditUser
      v-bind:show="showEditModal"
      v-bind:close="closeEditModal"
      @close="closeEditModal"
      @alertSuccess="alertSuccess"
      @alertError="alertError"
      @reload="getAllUser"
      :getType="getType"
      :accessRightP="accessRight"
      :transactionTypeP="transactionType"
      :role="allRole"
      v-bind:singleUser="singleUser"
      v-if="checkPayloadValid(singleUser)"
    />
    <ContentHeader
      v-bind:title="content_header.title"
      v-bind:subTitle="content_header.subTitle"
    />

    <div class="page__body">
      <div class="page__body__header">
        <div class="row align-items-center justify-content-between">
          <div class="col-4">
            <!-- <label
              for="text"
              class="has-checkbox page__body__header__label text--capital"
              >Show deactivated/locked users
              <input
                type="checkbox"
                id="text"
                class="form-"
                @change="toggleStatus()"
                v-model="state"
              />
              <span class="checkmark"></span>
            </label> -->
          </div>
          <div class="col-8">
            <div class="row align-items-center justify-content-end">
              <div class="align-self-center mx-2 dropdown">
                <button
                  type="button"
                  class="
                    w-100
                    button
                    page__body__header__button
                    button--grey
                    text--capital
                  "
                  @click="toggleFilterModal"
                >
                  <span class="d-inline-flex mr-2">filter</span>
                </button>
              </div>
              <div class="align-self-center mx-2 dropdown">
                <button
                  class="
                    button button--grey
                    text--capital text--small
                    d-flex
                    align-items-center
                  "
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  export
                  <span class="dropdown__icon">
                    <ion-icon name="caret-down-outline"></ion-icon>
                  </span>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a
                    class="dropdown-item text--capital"
                    href="javascript:void(0)"
                    v-if="userCanExportToPDF"
                    @click="pdfDownload"
                    >PDF</a
                  >
                  <a
                    class="dropdown-item text--capital"
                    href="javascript:void(0)"
                    v-if="userCanExportToExcel"
                    @click="processDownload"
                    >excel</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="display:flex; width:50%; font-size:14px" v-if="filterText">
        <div style="width:80%">
          {{ filterText }}
        </div>
        <div style="display:flex;width:10%">
          <span style="cursor:pointer;font-size:17px" @click="editFilter">
            <i style="padding-right:5px" class="bi bi-pencil-square"></i>
          </span>
          <span style="cursor:pointer;font-size:17px" @click="deleteFilter">
            <i style="padding-left:5px" class="bi bi-x-circle-fill"></i>
          </span>
        </div>
      </div>
      <table class="customtable w-100">
        <thead>
          <tr>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 20%"
            >
              name & email
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 12%"
            >
              last login
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 13%"
            >
              role
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 12%"
            >
              date created
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 12%"
            >
              branch
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 10%"
            >
              admin
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 10%"
            >
              teller
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 10%"
            >
              account officer
            </th>
            <th
              scope="col"
              class="table__header__text table__text text--capital"
              style="width: 10%"
            >
              API 1.0 access
            </th>
          </tr>
        </thead>
        <!-- <tbody> -->
        <tbody v-if="userIsNotAuthorised">
          <tr>
            <td colspan="12">
              <div
                class="
                  d-flex
                  flex-column
                  align-items-center
                  justify-content-center
                  p5
                "
              >
                <img src="@/assets/img/empty.svg" alt="delete" srcset="" />

                <span
                  class="d-block text--danger text--600 text--medium mt-3 mb-5"
                  >You do not have view permission </span
                >
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-if="loadingUsersData">
          <tr>
            <td colspan="12">
              <div class="d-flex align-items-center justify-content-center p5">
                <div
                  class="spinner-border"
                  style="width: 3rem; height: 3rem"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
                <span
                  class="text--black text--600 text--medium d-inline-flex ml-4"
                  >Please wait</span
                >
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="allUser && allUser.data && allUser.data.length">
          <tr
            v-for="(row, ri) in allUser.data"
            v-bind:key="ri"
            :class="[row.userStatus.toLowerCase() === 'inactive' ? 'text-danger' : '']"
          >
            <!-- <tr> -->
            <td>
              <div class="align-self-center">
                <span
                  @click="viewRoute(row.userKey)"
                  class="table__body__text table__text py-1 d-block text--upper text--primary cursor"
                >
                  {{ row.name }}
                </span>
                <span
                  class="
                    table__body__text table__text
                    d-block
                    py-1
                    table__text--small
                  "
                >
                  {{ row.userEmail }}
                </span>
              </div>
            </td>
            <td>
              <div class="align-self-center" style="padding-right: 15px;">
                <span class="table__body__text table__text text--capital"
                  >{{ row.lastLogin | moment }}</span
                >

                <span class="table__body__text table__text d-block py-1 table__text--small"
                  >{{ row.lastLogin | times }}</span
                >
              </div>
            </td>
             <td>
              <span class="table__body__text table__text text--capital" style="padding-right: 15px;">
                {{ row.role }}
              </span>
            </td>
            <td>
              <div class="align-self-center" style="padding-right: 15px;">
                <span class="table__body__text table__text text--capital"
                  >{{ row.creationDate | moment }}</span
                >

                <span class="table__body__text table__text d-block py-1 table__text--small"
                  >{{ row.creationDate | times }}</span
                >
              </div>
            </td>
            <td>
              <span class="table__body__text table__text text--capital">
                {{ row.branchAssigned }}
              </span>
            </td>
            <td style="vertical-align: top !important">
              <span class="table__body__text table__text text--capital">
                <label
                  for="a"
                  class="has-checkbox page__body__header__label text--capital"
                >
                  <input
                    type="checkbox"
                    id="a"
                    class="form-"
                    disabled
                    :checked="row.isAdministrator"
                  />
                  <span class="checkmark"></span>
                </label>
              </span>
            </td>
            <td style="vertical-align: top !important">
              <label
                for="b"
                class="has-checkbox page__body__header__label text--capital"
              >
                <input
                  type="checkbox"
                  id="b"
                  class="form-"
                  disabled
                  :checked="row.isTeller"
                />
                <span class="checkmark"></span>
              </label>
            </td>
            <td style="vertical-align: top !important">
              <label
                for="c"
                class="has-checkbox page__body__header__label text--capital"
              >
                <input
                  type="checkbox"
                  id="c"
                  class="form-"
                  disabled
                  :checked="row.isCreditOfficer"
                />
                <span class="checkmark"></span>
              </label>
            </td>
            <td style="vertical-align: top !important">
              <label
                for="d"
                class="has-checkbox page__body__header__label text--capital"
              >
                <input type="checkbox" id="d" class="form-" disabled />
                <span class="checkmark"></span>
              </label>
            </td>
            <!--<td>
              <b-dropdown text="Action" class="m-2 dropdown--button-grey">
                <b-dropdown-item href="#" @click="confirmStatus(row)">
                  {{ row.userStatus === "Active" ? "Deactivate" : "Activate" }}
                </b-dropdown-item>
                <b-dropdown-item href="#" @click="openEditModal(row)"
                  >Edit</b-dropdown-item
                >
                <b-dropdown-item
                  href="#"
                  @click="closeResetModal({ userKey: row.userKey })"
                  v-if="adminUser.isAdministrator && adminUser.userKey !== row.userKey"
                >Reset Password</b-dropdown-item
                >
                <b-dropdown-item
                  href="#"
                  @click="confirmDelete({ userKey: row.userKey })"
                  >Delete</b-dropdown-item
                >
              </b-dropdown>
            </td>-->
          </tr>
        </tbody>
      </table>

      <div class="pagination" v-if="allUser.data && !loading && !userIsNotAuthorised">
        <div class="d-flex align-items-center justify-content-between w-100">
          <div class="text--capital text--regular pagination__text">
            showing
            <span class="d-inline-block text--primary mr-3">
              <label for="">
                <select
                  v-model.number="fetchModel.pageSize"
                  class="select select--lg select--page"
                  @change="changePageSize()"
                >
                  <option
                    v-for="psize in pagesizes"
                    :key="psize.id"
                    :id="psize.id"
                    :selected="fetchModel.pageSize"
                  >
                    {{ psize.size }}
                  </option>
                </select>
              </label>
            </span>
            <span class="d-inline-block text--primary">{{
              allUser ? allUser.currentPage : 0
            }}</span>
            to
            <span class="d-inline-block text--primary">{{
              allUser ? allUser.totalPages : 0
            }}</span>
            <!--of
            <span>{{ allUser ? allUser.totalCount : 0 }}</span>-->
          </div>
          <div
            class="
              pagination__buttons
              d-flex
              align-items-center
              justify-content-between
            "
            v-if="allUser"
          >
            <button
              class="button button--sm text--regular"
              @click="changePage('previous')"
              v-if="allUser.hasPrevious || allUser.hasPreviousPage"
            >
              <ion-icon name="chevron-back-outline"></ion-icon>
            </button>

            <div v-if="!isLargeSizePaged" class="d-flex align-items-center pagination__buttons">
              <button
                v-for="page in allUser.totalPages"
                :key="page"
                :class="{
                  'text--white button--primary ': allUser.currentPage === page,
                }"
                class="button text--regular button--sm ml-2"
                @click="changePage(page)"
              >
                {{ page }}
              </button>
            </div>

            <div v-else class="text--capital text--regular pagination__text">
              Page
              <span class="d-inline-block text--primary">{{
                allUser.currentPage
              }}</span>
              of
              <span class="d-inline-block text--primary">{{
                allUser.totalPages
              }}</span>
              from <span class="d-inline-block text--primary">{{ allUser.totalCount }}</span> Records
            </div>

            <button
              class="button text--regular button--sm ml-2"
              @click="changePage('next')"
              v-if="allUser.hasNext || allUser.hasNextPage"
            >
              <ion-icon name="chevron-forward-outline"></ion-icon>
            </button>
          </div>
        </div>
      </div>
    </div>

    <DeleteModal />
    <CreateFilter
      :show="showFilterModal"
      @close="toggleFilterModal"
      @send-activate-filter="sendFilter"
      @reload="reloadFilter"
      @alertError="alertError"
      :filterEditValue="filterEdit"
      :showEditedValue="showEditedValue"
      :filterHeaderName="'User'"
      filterCategory="user"
    />
  </main>
</template>

<script>
// import JsonExcel from "vue-json-excel";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import { mapState } from "vuex";
import DeleteModal from "@/components/DeleteModal/_base-delete-modal.vue";
import CreateUser from "./Users/create.vue";
import EditUser from "./Users/edit.vue";
import {
  GET_ALL_USER,
  GET_ALL_ROLE,
  GET_USER,
  GET_TYPE,
  GET_ACCESS_RIGHT,
  GET_PERMISSION,
  GET_TRANSACTION_TYPE,
  GET_BRANCHES,
  GET_ALL_USER_EXPORTS,
} from "@/core/services/store/types";
import ApiService from "../../../core/services/access.service";
import { Role } from "@/core/services/role.js";


export default {
  name: "User",

  data: function () {
    return {
      showFilterModal: false,
      page: 1,
      size: 10,
      status: true,
      branchKey: "",
      userKey: false,
      userIsActivated: false,
      filters: [],
      fetchModel: {
        filters: [],
        pageIndex: 1,
        pageSize: 10,
        isFromFilter: false,
        filterCategory: "",
      },
      pagesizes: [
        {
          size: 10,
        },
        {
          size: 20,
        },
        {
          size: 50,
        },
      ],
      state: false,
      alert: {
        show: false,
        status: "",
        title: "Success",
        description: "User has been created",
      },
      confirm_delete: false,
      confirm_status: false,
      process: false,
      showCreateModal: false,
      showEditModal: false,
      content_header: {
        title: "Users",
        subTitle:
          "Manage the products offerings that you have available to your customers by creating and editing loan.",
      },
      json_fields: {
        Name: "name",
        Email: "email",
        "Last Login": "last_login",
        Role: "role",
        Admin: "admin",
        Teller: "teller",
        "Account Officer": "officer",
        "User Status": "status",
      },
      adminUser: "",
      exportData: [],
      filterText: "",
      filterEdit: [],
      showEditedValue: 0,
      paging: "",
    };
  },
  methods: {
    viewRoute(data) {
      this.$router.push({ path: `/user/${data}` });
    },
    toggleFilterModal() {
      this.showFilterModal = !this.showFilterModal;
    },
    sendFilter(value) {
      const filterArray = value.map(obj => {
        const payload = {};

        payload.filterFieldName = obj.filterName;
        payload.filterFieldOperator = obj.filterOp;
        if (obj.value1 === '' || obj.value1 === null) {
          payload.filterFieldValues = [obj.value];
          return payload;
        }
        payload.filterFieldValues = [obj.value, obj.value1];
        return payload;
      });
      this.fetchModel.filters = filterArray;
      this.fetchModel.pageIndex = 1;
      this.fetchModel.pageSize = 10;
      this.fetchModel.filterCategory = "user";
      this.fetchModel.isFromFilter = true;
      this.getAllFilteredUser();
    },
    getLoginUser() {
      ApiService.post("ProleanUser/getuserdetails", {})
        .then((response) => {
          const data = response.data.data;

          this.adminUser = this.allUser && this.allUser.data
            ? this.allUser.data.find((user) => user.userKey === data.userKey)
            : {};
        })
        // .catch((e) => {})
    },
    getAllUser() {
      const data = {
        pageNumber: this.fetchModel.pageIndex,
        pageSize: this.fetchModel.pageSize,
        branchKey: this.branchKey,
        status: this.status,
        filters: this.fetchModel.filters,
        filterCategory: this.fetchModel.filterCategory,
        pageIndex: this.fetchModel.pageIndex,
        isFromFilter: this.fetchModel.isFromFilter,
      };
      this.$store.dispatch(GET_ALL_USER, data)
      if (this.errorCode == 409) return this.alertWarning('You do not have permission to view');
    },
    getAllFilteredUser() {
      const data = {
        branchKey: this.branchKey,
        status: this.status,
        pageNumber: this.fetchModel.pageIndex,
        pageSize: this.fetchModel.pageSize,
        filters: this.fetchModel.filters,
        filterCategory: this.fetchModel.filterCategory,
        pageIndex: this.fetchModel.pageIndex,
        isFromFilter: this.fetchModel.isFromFilter,
      };
      this.$store.dispatch(GET_ALL_USER, data).then(() => {
        this.alertSuccess('Successfully filter users')
      })
      .catch((error) => {
        this.alertError(error.response.data.message);
      });
    },
    reloadFilter() {
      this.deleteFilter();
      this.fetchModel.isFromFilter = false;
      this.fetchModel.filterCategory = "";
      this.fetchModel.filters = [];
      this.fetchModel.pageIndex = 1;
      this.fetchModel.pageSize = 10;
      this.getAllUser();
    },
    deleteFilter() {
      this.filterText = "";
      this.filterEdit = []
    },
    editFilter() {
      this.showEditedValue = Math.random()
      this.toggleFilterModal();
    },
    getExportData() {
      if(this.allUser.totalCount > 0) {
        const data = {
          branchKey: this.branchKey,
          status: this.status,
          pageNumber: this.page,
          pageSize: this.allUser.totalCount,
          filters: this.fetchModel.filters,
          filterCategory: this.fetchModel.filterCategory,
          pageIndex: this.fetchModel.pageIndex,
          isFromFilter: this.fetchModel.isFromFilter,
        }
        this.$store.dispatch(GET_ALL_USER_EXPORTS, data).then(() => {
          this.alertSuccess('Successfully filter users')
        })
        .catch((error) => {
          this.alertError(error.response.data.message);
        });
      }
    },
    getAllRole() {
      this.$store.dispatch(GET_ALL_ROLE, {
        pageIndex: 1,
        pageSize: 100000,
        searchRoleName: "",
      });
    },
    processDownload() {
      let wb = XLSX.utils.book_new();

      wb.Props = {
        Title: "Users List Download",
        Subject: "Users List Excel Sheet",
        Author: "Prolean",
        createdDate: Date.now(),
      };

      wb.SheetNames.push("Users");

      let sheetData = [[
        "Name",
        "Email",
        "Last Login",
        "Role",
        "Date Created",
        "Branch",
        "Admin",
        "Teller",
        "Account Officer",
        "User Status",
      ]];
      if (this.allExports && this.allExports.data) {
        this.allExports.data.map((item) => {
          let new_arr = [];
          new_arr.push(item.name);
          new_arr.push(item.userEmail);
          new_arr.push(
            item.lastLogin ? moment(item.lastLogin).format("DD-MMM-YYYY hh:mm a") : ""
          );
          new_arr.push(item.role);
          new_arr.push(
            item.creationDate ? moment(item.creationDate).format("DD-MMM-YYYY hh:mm a") : ""
          );
          new_arr.push(item.branchAssigned);
          new_arr.push(item.isAdministrator ? "Yes" : "No");
          new_arr.push(item.isTeller ? "Yes" : "No");
          new_arr.push(item.isCreditOfficer ? "Yes" : "No");
          new_arr.push(item.userStatus);

          sheetData.push(new_arr);
        });
      }

      let ws = XLSX.utils.aoa_to_sheet(sheetData);

      wb.Sheets["Users"] = ws;

      let wbSheet = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

      this.saveExcelSheet(this.convertBinaryToOctetStream(wbSheet));
    },
    convertBinaryToOctetStream(sheet) {
      let buf = new ArrayBuffer(sheet.length);
      let view = new Uint8Array(buf);

      for (let i = 0; i < sheet.length; i++) {
        view[i] = sheet.charCodeAt(i) & 0xff;
      }

      return buf;
    },
    saveExcelSheet(data) {
      const url = URL.createObjectURL(
        new Blob([data], { type: "application/vnd.ms-excel" })
      );

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "users.xlsx");
      document.body.appendChild(link);
      link.click();
    },
    pdfDownload() {
      const columns = [
        { title: "Name", dataKey: "name" },
        { title: "Email", dataKey: "email" },
        { title: "Last Login", dataKey: "last_login" },
        { title: "Role", dataKey: "role" },
        { title: "Date Created", dataKey: "date" },
        { title: "Branch", dataKey: "branch" },
        { title: "Admin", dataKey: "admin" },
        { title: "Teller", dataKey: "teller" },
        { title: "Account Officer", dataKey: "officer" },
        { title: "User Status", dataKey: "status" },
      ];

      let doc = new jsPDF("l", "pt", "a4");
      doc.setFontSize(6);
      doc.autoTable(columns, this.download);
      doc.save("users.pdf");
    },
    getAccessRight() {
      this.$store.dispatch(GET_ACCESS_RIGHT);
    },
    getPermission() {
      this.$store.dispatch(GET_PERMISSION);
    },
    getTransactionType() {
      this.$store.dispatch(GET_TRANSACTION_TYPE);
    },
    getGetType() {
      this.$store.dispatch(GET_TYPE);
    },
    async getBranches() {
      const data = {
        branchName: null,
        branchState: "Active",
        pageIndex: 1,
        pageSize: 100,
        sortColumn: "",
        isDescending: false,
      };
      this.$store.dispatch(GET_BRANCHES, data);
    },
    toggleStatus() {
      if (this.state) {
        this.status = false;
      } else {
        this.status = true;
      }
      const data = {
        status: this.status,
        pageNumber: this.fetchModel.pageIndex,
        pageSize: this.fetchModel.pageSize,
        branchKey: this.branchKey,
        filters: this.fetchModel.filters,
        filterCategory: this.fetchModel.filterCategory,
        pageIndex: this.fetchModel.pageIndex,
        isFromFilter: this.fetchModel.isFromFilter,
      }
      this.$store.dispatch(GET_ALL_USER, data);
    },
    toggleBranch() {
      const data = {
        status: this.status,
        pageNumber: this.fetchModel.pageIndex,
        pageSize: this.fetchModel.pageSize,
        branchKey: this.branchKey,
        filters: this.fetchModel.filters,
        filterCategory: this.fetchModel.filterCategory,
        pageIndex: this.fetchModel.pageIndex,
        isFromFilter: this.fetchModel.isFromFilter,
      }
      this.$store.dispatch(GET_ALL_USER, data);
    },
    changePageSize() {
      this.fetchModel.pageIndex = 1;
      const data = {
        pageNumber: this.fetchModel.pageIndex,
        status: this.status,
        pageSize: this.fetchModel.pageSize,
        branchKey: this.branchKey,
        filters: this.fetchModel.filters,
        filterCategory: this.fetchModel.filterCategory,
        pageIndex: this.fetchModel.pageIndex,
        isFromFilter: this.fetchModel.isFromFilter,
      }
      this.$store.dispatch(GET_ALL_USER, data);
    },
    changePage(page = null) {
      if (page === "next") {
        // const nextPage = this.allUser.hasNext
        //   ? this.allUser.currentPage + 1
        //   : 1;
        this.fetchModel.pageIndex++;
        const data = {
          pageNumber: this.fetchModel.pageIndex,
          pageSize: this.fetchModel.pageSize,
          status: this.status,
          branchKey: this.branchKey,
          filters: this.fetchModel.filters,
          filterCategory: this.fetchModel.filterCategory,
          pageIndex: this.fetchModel.pageIndex,
          isFromFilter: this.fetchModel.isFromFilter,
        }
        this.$store.dispatch(GET_ALL_USER, data);
      } else if (page === "previous") {
        // const nextPage = this.allUser.hasPrevious
        //   ? this.allUser.currentPage - 1
        //   : 1;
        this.fetchModel.pageIndex = this.fetchModel.pageIndex - 1;
        const data = {
            pageNumber: this.fetchModel.pageIndex,
            pageSize: this.fetchModel.pageSize,
            status: this.status,
            branchKey: this.branchKey,
            filters: this.fetchModel.filters,
            filterCategory: this.fetchModel.filterCategory,
            pageIndex: this.fetchModel.pageIndex,
            isFromFilter: this.fetchModel.isFromFilter,
          }
        this.$store.dispatch(GET_ALL_USER, data);
        
      } else if (page !== null) {
        this.fetchModel.pageIndex = page;
        this.$store.dispatch(GET_ALL_USER, {
            pageNumber: page,
            pageSize: this.fetchModel.pageSize,
            status: this.status,
            branchKey: this.branchKey,
            filters: this.fetchModel.filters,
            filterCategory: this.fetchModel.filterCategory,
            pageIndex: this.fetchModel.pageIndex,
            isFromFilter: this.fetchModel.isFromFilter,
        });
      }
    },
    confirmDelete(row) {
      if (row) this.userKey = row.userKey;

      this.alert.show = false;
      if (!this.confirm_delete) {
        this.confirm_delete = true;
      } else {
        this.confirm_delete = false;
      }
    },
    closeResetModal(row) {
      if (row) this.userKey = row.userKey;

      this.alert.show = false;
      if (!this.confirm_delete) {
        this.confirm_delete = true;
      } else {
        this.confirm_delete = false;
      }
    },
    confirmStatus(row) {
      if (row) {
        this.userKey = row.userKey;
        this.userIsActivated = row.userStatus == "Active" ? false : true;
      }

      this.alert.show = false;
      if (!this.confirm_status) {
        this.confirm_status = true;
      } else {
        this.confirm_status = false;
      }
    },

    toggeleItem() {
      this.process = true;
      ApiService.put(
        `ProleanUser/ActivateDeactivateUser/userKey/${
          this.userKey
        }/${this.userIsActivated}`
      )
        .then((res) => {
          this.process = false;
          this.confirm_status = false;
          if (res.data.status) {
            this.getAllUser();
            this.alertSuccess(res.data.message);
          } else {
            this.alertError(res.data.message);
          }
        })
        .catch((e) => {
          this.confirm_status = false;
          try {
            this.process = false;
            this.alertError(e.response.data.message);
          } catch (e) {
            this.alertError(
              "An unexpected Error occurred: Kindly confirm you are connected to the internet"
            );
          }
        });
    },
    deleteItem() {
      this.process = true;
      ApiService.delete(
        `ProleanUser/DeleteUser/${this.userKey}`
      )
        .then((res) => {
          this.process = false;
          this.confirm_delete = false;
          if (res.data.status) {
            this.getAllUser();
            this.alertSuccess(res.data.message);
          } else {
            this.alertError(res.data.message);
          }
        })
        .catch((e) => {
          this.confirm_delete = false;
          try {
            this.process = false;
            this.alertError(e.response.data.message);
          } catch (e) {
            this.alertError(
              "An unexpected Error occurred: Kindly confirm you are connected to the internet"
            );
          }
        });
    },

    confirmResetPassword() {
      this.process = true;
      ApiService.post(
        `ProleanUser/resetuserpassword`,
        { userKey: this.userKey }
      )
        .then((res) => {
          this.process = false;
          this.confirm_delete = false;
          if (res.data.status) {
            this.getAllUser();
            this.alertSuccess(res.data.message);
          } else {
            this.alertError(res.data.message);
          }
        })
        .catch((e) => {
          this.confirm_delete = false;
          try {
            this.process = false;
            this.alertError(e.response.data.message);
          } catch (e) {
            this.alertError(
              "An unexpected Error occurred: Kindly confirm you are connected to the internet"
            );
          }
        });
    },

    checkPayloadValid(ob) {
      if (Object.keys(ob).length == 0) {
        return false;
      }
      return true;
    },
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertWarning(description) {
      this.alert.status = "warn";
      this.alert.title = "Warning";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    openCreateModal() {
      this.showCreateModal = true;
    },
    openEditModal(row = null) {
      this.user = row;
      this.$store.dispatch(GET_USER, row.userKey);
      this.showEditModal = true;
    },
    closeCreateModal() {
      this.showCreateModal = false;
    },
    closeEditModal() {
      this.showEditModal = false;
    },
  },
  components: {
    // downloadExcel: JsonExcel,
    DeleteModal,
    CreateUser,
    EditUser,
    CreateFilter: () => 
      import(
        /* WebpackChunkName: "CreateFilter" */ "@/view/pages/deposit/filter.vue"
      )
  },
  computed: {
    ...mapState({
      singleUser: (state) => state.access?.user?.data || {},
      allUser: (state) => state.access?.allUser || [],
      loadingUsersData: (state) => state.access.loadingUser,
      allExports: (state) => state.access?.allExports || [],
      allRole: (state) => state.access?.allRole?.data || [],
      getType: (state) => state.access?.getType?.data || [],
      accessRight: (state) => state.access?.accessRight?.data || [],
      transactionType: (state) => state.access?.transactionType?.data || [],
      loading: (state) => state.loan.loading,
      permission: (state) => state.access?.permission?.data || [],
      branches: (state) => state.access?.branches?.data?.items || [],
      errorCode: (state) => state.access.errorCode || '',
      errorMessage: (state) => state.access.errorMessage || '',
      user: (state) => state.auth.user,
    }),

    isLargeSizePaged() {
      return this.allUser?.totalPages > 10;
    },

    userIsNotAuthorised() {
      return this.errorCode == 409;
    },

    userCanExportToExcel() {
      const user = this.user.scopes.find(item => item.scopeName === Role.Documents);
      const { permissionsList } = user;
      return permissionsList.CanExportToExcel;
    },

    userCanExportToPDF() {
      const user = this.user.scopes.find(item => item.scopeName === Role.Documents);
      const { permissionsList } = user;
      return permissionsList.CanDownloadDocument;
    },

    download() {
      let data = [];
      if (this.allExports.data) {
        this.allExports.data.forEach((item) => {
          let new_obj = {};
          new_obj.name = item.name;
          new_obj.email = item.userEmail;
          new_obj.last_login = item.lastLogin
            ? moment(item.lastLogin).format("DD-MMM-YYYY a")
            : "";
          new_obj.role = item.role;
          new_obj.date = item.creationDate
            ? moment(item.creationDate).format("DD-MMM-YYYY hh:mm a")
            : "";
          new_obj.branch = item.branchAssigned;
          new_obj.admin = item.isAdministrator ? "Yes" : "No";
          new_obj.teller = item.isTeller ? "Yes" : "No";
          new_obj.officer = item.isCreditOfficer ? "Yes" : "No";
          new_obj.status = item.userStatus;

          data.push(new_obj);
        });
      }

      return data;
    },
  },
  watch: {
    allExports(){
      this.filterText = this.allExports.filterText ? this.allExports.filterText.replace(/_/g, " ") : "";
      this.filterEdit = this.allExports.filters ? this.allExports.filters : [];
    },
    allUser() {
      this.filterText = this.allUser.filterText ? this.allUser.filterText.replace(/_/g, " ") : "";
      this.filterEdit = this.allUser.filters ? this.allUser.filters : [];
      if(this.allUser.filterText && this.allUser.filters) {
        this.getExportData()
      }
    }
  },
  filters: {
    moment: function (date) {
      return date != null ? moment(date).format("Do-MMM-YYYY") : "";
    },
    times: function (date) {
      return date != null ? moment(date).format("hh:mm a") : "";
    },
  },
  mounted() {
    this.getAllUser();
    this.getAllRole();
    this.getGetType();
    this.getAccessRight();
    this.getPermission();
    this.getTransactionType();
    this.getBranches();
    this.getLoginUser();
  },
};
</script>
<style lang="scss" scoped>
  .text-danger {
    & span {
      color: #FA4169 !important;
    }
  }
  .cursor {
    cursor: pointer;
  }
</style>
